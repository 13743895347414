import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {setCookie} from './components/helpers/CookiesHelper';
import store from './store/index.js'
import SecondSpinner from "@/components/ui/SecondSpinner.vue"
import BaseDialog from "@/components/ui/BaseDialog.vue"
import SecondDialog from "@/components/ui/SecondDialog.vue"
import BaseSpinner from "@/components/ui/BaseSpinner.vue"
import CKEditor from '@ckeditor/ckeditor5-vue';
import i18n from './i18n'
import { svgSpritePlugin } from 'vue-svg-sprite'
// import Vue3Sanitize from "vue-3-sanitize";
const axios = require('axios').default;
const app = createApp(App)
app.use(router).use(store).use(CKEditor).use(i18n).use(svgSpritePlugin, {
    url: '/images/sprite.svg',
    class: 'icon',
})
// .use(Vue3Sanitize);

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response) {
       switch (error.response.status) {
           case 401:
            if(error.response.config.url !== '/auth/pw/login' && router.currentRoute.value.path !== '/about'){
                if(router.currentRoute.value.path !== '/login'){
                    setCookie('redirect', router.currentRoute.value.fullPath, {secure: true, 'max-age': 72000})
                }
                router.replace({
                    path: '/login'
                })
            } else {
                error['message'] = "User not found"
            }
            break;
           case 413:
            error['message'] = "Request Entity Too Large"
       }
       console.log("err", error);
       return Promise.reject(error);
   }
});

app.component('base-dialog', BaseDialog)
app.component('second-dialog', SecondDialog)
app.component('second-spinner', SecondSpinner)
app.component('base-spinner', BaseSpinner)
app.mount('#app')
