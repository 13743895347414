import router from '@/router'

export default {
  setUserInfo(state, payload) {
    state.user_info = payload
  },
  createContact(state, payload) {
    state.contacts['contacts'].push({...payload})
    state.contacts['total']++
  },
  updateContact(state, payload) {
    const i = state.contacts['contacts'].findIndex(el => el.id === payload.id)
    if (i !== -1) {
      state.contacts['contacts'][i] = {...payload}
    }
  },
  removeContact(state, payload) {
    const i = state.contacts['contacts'].findIndex(el => el.id === payload)
    if (i !== -1) {
      state.contacts['contacts'].splice(i, 1)
      state.contacts['total']--
    }
  },
  setContacts(state, payload) {
    state.contacts = payload
  },
  setMails(state, payload) {
    console.log('state.account.settings', state.account?.settings?.unread_first)
    if (payload.length === 0 || state.openMailsFromSettings) {
      state.openMailsFromSettings = false
      state.mails = payload
      return
    }
    if(!payload.length){
      return
    }
    let id = ""
    if (state.mails && state.mails[0] && state.mails[0].id) {
      id = state.mails[0].id
      let index = -1
      payload.forEach((el, i) => {
        if (el.id === id) {
          index = i
          return
        }
      })
      if (index !== -1) {
        const arr_start = payload.slice(0, index)
        const payload_end = payload.slice(index)
        const map_start = new Map()
        arr_start.forEach(el => {
          map_start.set(el.id, el)
        })
        const map_payload_end = new Map()
        payload_end.forEach(el => {
          map_payload_end.set(el.id, el)
        })
        const map_old = new Map()
        state.mails.forEach(el => {
          map_old.set(el.id, el)
        })

        console.log('msg_id', router.currentRoute.value.params.msg_id)
        console.log('state.mails[0].uid', state.mails[0].uid)
        console.log('index', index)
        if(index > 0 && router.currentRoute.value?.params?.msg_id && +router.currentRoute.value.params.msg_id === state.mails[0].uid) {
          console.log('1')
          let k = 0
          map_start.forEach((value, key) => {
            if (map_old.has(key)) {
              k++
            }
          })
          console.log('map_start', map_start)
          console.log('map_start.size', map_start.size)
          console.log('k', k)
          if (k === map_start.size) {
            return
          }
        }

        let j = 0
        map_start.forEach((value, key) => {
          if (map_old.has(key)) {
            map_old.delete(key)
            j++
          }
        })
// eslint-disable-next-line
        const arr_after_delete = Array.from(map_old).map(([name, value]) => ({...value}))
        const arr_end = arr_after_delete.slice(0, state.mails.length - index + j)
        arr_end.forEach(el => {
          if(map_payload_end.has(el.id)){
            el.flags = map_payload_end.get(el.id).flags
            el.labels = map_payload_end.get(el.id).labels
            if(map_payload_end.get(el.id).threads && el.threads && el.threads.length > 0){
              const threads_new = new Map()
              map_payload_end.get(el.id).threads.forEach(el => {
                threads_new.set(el.id, el)
              })
              el.threads.forEach(elem => {
                if(threads_new.has(elem.id)){
                  elem.flags = threads_new.get(elem.id).flags
                  elem.labels = threads_new.get(elem.id).labels
                }
              })
            }
          }
        })
        state.mails = [...arr_start, ...arr_end]
      } else {
        if(state.socket === 'store' && state.account?.settings?.unread_first){
          console.log('store')
          return;
        } else {
          state.mails = payload
        }
      }
    } else {
      state.mails = payload
    }
  },
  setFolders(state, payload) {
    state.folders = [payload]
  },
  setFoldersCollectors(state, payload) {
    state.foldersCollectors = payload
  },
  setLabels(state, payload) {
    state.labels = payload
  },
  addNewLabel(state, payload) {
    state.labels.push(payload)
  },
  setUser(state, payload) {
    state.user = payload
  },
  setTotal(state, payload) {
    state.total = payload
  },
  setAll(state, payload) {
    state.all = payload
  },
  openedMail(state, payload) {
    state.opened_mail = payload
  },
  removeMailsFromFolder(state, payload) {
    payload.forEach(uid => {
      const i = state.mails.findIndex(el => el.uid === uid)
      if (i !== -1) {
        state.mails.splice(i, 1)
      }
    });
  },
  setUserAccount(state, payload) {
    let aliases = payload['aliases']
    const wmid = payload['email'].substring(0, 12)
    let a1 = []
    let a2 = []
    let a3 = []
    aliases.forEach(el => {
      let ar1 = el.email.split('@')
      if (ar1 && ar1.length > 0) {
        if (ar1[0] === wmid) {
          a1.push(el)
        } else if (ar1[0].length === 13 && ar1[0][0].match(/^[a-zA-Z]$/) && ar1[0].substring(1,).match(/^[0-9]{12}$/)) {
          el.email = el.email.charAt(0).toUpperCase() + el.email.slice(1)
          a3.push(el)
        } else {
          a2.push(el)
        }
      }
    })
    a2.sort((a, b) => {
      const aliasA = a.email.split('@')[0]
      const aliasB = b.email.split('@')[0]
      return aliasA.localeCompare(aliasB)
    })
    a3.sort((a, b) => a.email.charAt(0).localeCompare(b.email.charAt(0)))
    a1 = a1.concat(a2)
    a1 = a1.concat(a3)
    payload['aliases'] = a1
    state.account = payload
  },
  setBa(state, payload) {
    state.ba = payload
  },
  setLeftSidebarSelect(state, payload) {
    state.left_sidebar_select = payload
  },
  setSettnigs(state, payload) {
    state.settings = payload
    state.account.settings = payload
  },
  setLanguage(state, payload) {
    state.language = payload
  },
  setFilters(state, payload) {
    state.filters = payload
  },
  setStateConnection(state, payload) {
    state.connection = payload
  },
  setGroups(state, payload) {
    state.groups = payload
  },
  addGroup(state, payload) {
    state.groups.push(payload)
  },
  addingContactsToGroup(state, payload) {
    const i = state.groups.findIndex(el => el.id === payload.id)
    if (i !== -1) {
      if (!state.groups[i].Сontacts) {
        state.groups[i].Сontacts = []
      }
      state.groups[i].Сontacts = state.groups[i].Сontacts.concat(payload.contacts.contacts)
    }
  },
  deleteGroup(state, payload) {
    const i = state.groups.findIndex(el => el.id === payload)
    if (i !== -1) {
      state.groups.splice(i, 1)
    }
  },
  updateGroup(state, payload) {
    const i = state.groups.findIndex(el => el.id === payload.id)
    if (i !== -1) {
      state.groups[i].name = payload.name
      if (payload.contacts) state.groups[i].contacts = payload.contacts.slice()
    }
  },
  setIsSearchMode(state, payload) {
    state.isSearch = payload
  },
  setSearchPagination(state, payload) {
    state.searchPagination.counter = payload.counter
    state.searchPagination.type = payload.type
  },
  updateMailsList(state, payload) {
    state.mails = [...payload]
  },
  setAliasAccesses(state, payload) {
    if (payload.delete_access) {
      const i = state.account?.alias_accesses?.findIndex(el => el.alias === payload.alias)
      if (i !== -1)
        state.account.alias_accesses.splice(i, 1)
    } else {
      state.account?.alias_accesses?.push({alias: payload.alias})
    }
  },
  removeAliasAccessed(state, payload) {
    if (!state.account?.alias_accesses || state.account?.alias_accesses.length === 0) return
    const i = state.account.alias_accesses.findIndex(el => el.alias === payload)
    if (i !== -1) {
      state.account.alias_accesses.splice(i, 1)
    }
  },
  setCollectors(state, payload) {
    state.collectors = payload
  },
  setCollector(state, payload) {
    const i = state.collectors.findIndex(el => el.id === payload.id)
    if (i !== -1) {
      state.collectors[i] = payload
    }
  },
  removeCollector(state, payload) {
    const i = state.collectors.findIndex(el => el.id === payload)
    if (i !== -1) {
      state.collectors.splice(i, 1)
    }
  },
  updateMailsListSettings(state) {
    state.openMailsFromSettings = true
  },
  setSocket(state, payload){
    state.socket = payload
  }
}
