import axios from "axios";
import {SIGN_ON_URL} from '@/components/helpers/constants'
export default {
  async getUserInfo(context){
    const response = await axios.get(context.getters.url1+"user");
    context.commit("setUserInfo", response.data[0])
  },
  async getRid(context){
    const resp = await axios.get(context.getters.url2+'rids')
    if(resp?.data?.RID) return resp.data.RID
    return
  },
  async getLabelMails(context, payload){
    let signal = {}
    if(payload?.controller?.signal){
      signal = {
        signal: payload.controller.signal
      }
    }
    const response = await axios.post(context.getters.url1+"messages/search", payload.payload, signal)
    if(response?.data){
      if(response.data.messages){
        context.commit("setMails", response.data.messages)
      }
      context.commit("setTotal", response.data.total)
      context.commit("setAll", response.data.all)
    }
  },
  async createContact(context, payload){
    const response = await axios.post(context.getters.url1+"contacts", {...payload})
    if(response?.data){
      if(response?.data?.emails?.length > 0){
        response.data.email = response.data.emails.toString()
      }
      context.commit("createContact", response.data)
    }
  },
  async updateContact(context, payload){
    if(payload && payload.id){
      const response = await axios.post(context.getters.url1+"contacts/"+payload.id, {...payload})
      if(response?.data){
        if(response?.data?.emails?.length > 0){
          response.data.email = response.data.emails.toString()
        }
        context.commit("updateContact", response.data)
      }
    }
  },
  async removeContact(context, payload){
    const response = await axios.delete(context.getters.url1+"contacts/"+payload)
    if(response )
      context.commit("removeContact", payload)
  },
  async getContacts(context, payload){
    const response = await axios.get(context.getters.url1+"contacts?page="+payload.page+"&size="+payload.limit)
    if(response?.data)
      context.commit("setContacts", response.data)
  },
  async getContact(context, payload){
    const response = await axios.get(context.getters.url1+"contacts/"+payload)
    if(response?.data)
      return response.data
    return
  },
  async getSettings(context){
    const response = await axios.get(context.getters.url1+"user/settings")
    context.commit("setSettnigs", response.data.settings)
  },
  async postSettings(context, payload){
    const response = await axios.post(context.getters.url1+"user/settings", {...payload})
    context.commit("setSettnigs", response.data.settings)
  },
  async setAliasPsw(context, payload){
    await axios.put(context.getters.url1+"user/aliases/update_access", {...payload})
    context.commit('setAliasAccesses', payload)
  },
  async getMails(context, payload){
    const url = context.getters.url1+payload.path+"?"+"page="+payload.page+'&limit='+payload.limit
    const response = await axios.get(url, {
      signal: payload.controller.signal
    })
    if(response?.data){
      if(response.data.messages){
        context.commit("setMails", response.data.messages)
      }
      context.commit("setTotal", response.data.total)
      context.commit("setAll", response.data.all)
    }
  },
  async getFolders(context, payload){
    let response = null
    if(payload?.controller?.signal){
      response = await axios.get(context.getters.url1+"folders", {
        signal: payload.controller.signal
      })
    } else {
      response = await axios.get(context.getters.url1 + "folders")
    }

    let hasCollectors = false

    if(response?.data?.folders?.subfolders){
      response?.data?.folders?.subfolders.forEach(el => {
        if(el.name.indexOf('.import.') !== -1){
          el.isCollector = true
          hasCollectors = true
        }
      })
    }

    if(response?.data?.folders?.subfolders && hasCollectors){
      const collectors = await context.dispatch('getCollectors')
      let collectorsHash = {}
      collectors.forEach(el => {
        collectorsHash[el.folder_name] = el.email
      })
      response.data.folders.subfolders = response.data.folders.subfolders.filter(el => {
        if(el.isCollector){
          if(el?.name && collectorsHash[el.name]){
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      })
      response.data.folders.subfolders.forEach(el => {
        if (el.isCollector){
          response.data.folders.subfolders.forEach(el=>{
            if(el?.name && collectorsHash[el.name]) {
              el.name = collectorsHash[el.name]
              // el.subfolders = []
            }
          })
        }
      })
    }

    if(response?.data?.folders){
      context.commit("setFolders", {...response.data.folders})
    }
  },   
  setFolders1(context, payload){
    context.commit("setFolders", payload)
  },  
  async getLabels(context){
    const response = await axios.get(context.getters.url1+"labels")
    context.commit("setLabels", response.data.labels)
  },
  async createMail(context, payload){
    let path = '/send'
    if(payload.isDraft)
      path = '/draft'
    const resp = await axios.post(context.getters.url1+"messages"+path, {...payload.body})
    if(resp?.data?.message?.uid) return resp.data.message.uid
    return
  },
  async loadUser(context){
    const response = await axios.get(context.getters.url2+"access")
    context.commit('setUser', response.data) 
  },
  async getMail(context, payload){
    const response = await axios.get(context.getters.url1+"messages/"+payload.path, {
      signal: payload.controller.signal
    })
    if(response?.data)
      context.commit('openedMail', response.data)
  },
  async translate(context, payload){
    const response = await axios.get(context.getters.url1+"messages/"+payload.path, {
      signal: payload.controller.signal
    })
    return response?.data
  },
  async createLabel(context, payload){
    let url = context.getters.url1+"labels"
    if(payload.id)
      url = url+"/"+payload.id
    const response = await axios.post(url, { ...payload }) 
    context.commit('addNewLabel', response.data)
  },
  async deleteMessage(context, payload){
    await axios.post(context.getters.url1+"messages/delete", { ...payload })
  },
  async markMessage(context, payload){
    await axios.post(context.getters.url1+"messages/mark", { ...payload})
  },
  async searchMails(context, payload){
    const response = await axios.post(context.getters.url1+"messages/search", { ...payload.search}, {
      signal: payload.controller.signal
    })
    if(response?.data){
      if(response.data.messages){
        context.commit("setMails", response.data.messages)
      }
      context.commit("setTotal", response.data.total)
      context.commit("setAll", response.data.all)
    }
  },
  async moveToFolder(context, payload){
    await axios.post(context.getters.url1+"messages/move", payload.payload)
    context.commit('removeMailsFromFolder', payload.uids)
  },
  async loginPassword(context, payload){
    await axios.post(context.getters.url2+"pw/login", { ...payload})
  },
  async loadUserAccount(context){
    let response = await axios.get(context.getters.url1+"user/account")

    context.commit('setUserAccount', response.data) 
  },
  async loadBa(context){
    const response = await axios.get(context.getters.url1+"user/settings/security/ba-list")
    context.commit('setBa', response.data)
  },
  setLeftSidebarSelect(context, payload){
    context.commit('setLeftSidebarSelect', payload) 
  },
  setLanguage(context, payload){
    context.commit('setLanguage', payload)
  },
  async saveProfile(context, action_payload){
    let url = context.getters.url1+"user/profiles"
    if(action_payload.id)
      url = url + '/' + action_payload.id
    await axios.post(url, { ...action_payload.payload})
    // context.commit("setProfiles", response.data)
  },
  async saveAlias(context, payload){
    await axios.post(context.getters.url1+"user/aliases", { ...payload})
    // context.commit('aliaseWasSaved')
  },
  async createFolder(context, payload){
    let url = context.getters.url1+"folders"
    if(payload['id'])
      url = url + '/'+payload['id']
    const response = await axios.post(url, { ...payload.folder})
    if(response?.data?.folder)
      return response.data.folder
    return
  },
  async removeFolder(context, payload){
    await axios.delete(context.getters.url1+"folders/"+payload)
  },
  async removeAll(context, payload){
    await axios.post(context.getters.url1+"messages/empty", { ...payload})
  },
  async removeProfile(context, payload){
    await axios.delete(context.getters.url1+"user/profiles/"+payload)
  },
  async removeLabel(context, payload){
    await axios.delete(context.getters.url1+"labels/"+payload)
  },
  async getContactsList2(context,payload){
    let response
    if(payload.controller)
      response = await axios.get(context.getters.url1+'contacts?size=1000&search='+payload.search, {
        signal: payload.controller.signal
      })
    else
      response = await axios.get(context.getters.url1+'contacts?size=1000&search='+payload.search)
    if(response?.data)
      return response.data
    return null
  },
  async getContactsList(context,payload){
    const response = await axios.get(context.getters.url1+'contacts?size=1000&search='+payload.search, {
      signal: payload.controller.signal
    })
    if(response?.data?.contacts)
      return response.data.contacts
    return null
  },
  async loadFilters(context){
    const response = await axios.get(context.getters.url1+'filters')
    if(response?.data?.sieve_filter?.filters)
      context.commit("setFilters", response.data.sieve_filter.filters)
  },
  async saveFilter(context, payload){
    const response = await axios.post(context.getters.url1+'filters', {...payload})
    if(response?.data?.filters?.filters)
      context.commit("setFilters", response.data.filters.filters)
  },
  setConnection(context, payload){
    context.commit("setStateConnection", payload)
  },
  async loadGroups(context){
    const response = await axios.get(context.getters.url1+'contacts/groups')
    if(response?.data)
      context.commit("setGroups", response.data)
  },
  async createGroup(context, payload){
    const response = await axios.post(context.getters.url1+'contacts/groups', {...payload})
    if(response?.data){
      context.commit("addGroup", response.data)
      return response.data
    }
    return
  },
  async addContactsToGroup(context, payload){
    await axios.post(context.getters.url1+'contacts/groups/'+payload.id+'/contacts', payload.contacts)
    context.commit("addingContactsToGroup", payload)
  },
  async removeGroup(context, payload){
    await axios.delete(context.getters.url1+'contacts/groups/'+payload)
    context.commit("deleteGroup", payload)
  },
  async updateGroup(context, payload){
    await axios.post(context.getters.url1+'contacts/groups/'+payload.id, payload)
    context.commit("updateGroup", payload)
  },
  async loadLogs(context){
    return await axios.get(context.getters.url1+'user/settings/security/auth-log')
  },
  async setAutoPsw(context){
    await axios.post(context.getters.url1+'user/settings/security/password/new')
  },
  async impersonation(context, payload){
    await axios.post(context.getters.url1+'user/settings/security/impersonation?wmid='+payload)
  },
  async sendcode(context){
    await axios.post(context.getters.url1+'user/settings/security/password/sendcode')
  },
  async changePsw(context, payload){
    await axios.post(context.getters.url1+'user/settings/security/password/change', payload)
  },
  async loadGroup(context, payload){
    const response = await axios.get(context.getters.url1+`contacts/groups/${payload}`)
    if(response?.data) return response.data
    return
  },
  async decryptMsg(context, payload){
    const response = await axios.post(context.getters.url1+'messages/decrypt', payload)
    if(response?.data) return response.data
    return
  },
  async decryptFromOutside(context, payload){
    const response = await axios.post(context.getters.url1+'decrypt', payload)
    if(response?.data) return response.data
    return
  },
  async getEml(context, payload){
    const response = await axios.get(context.getters.url1+payload)
    if(response?.data) return response.data
    return
  },
  async getDomain(context, payload){
    const response = await axios.get(context.getters.url1+'user/domains/'+payload)
    if(response?.data?.domain) return response.data.domain
    return
  },
  async getDomains(context){
    const response = await axios.get(context.getters.url1+'user/domains')
    if(response?.data?.domains) return response.data.domains
    return
  },
  async postDomain(context, payload){
    const response = await axios.post(context.getters.url1+'user/domains', payload)
    if(response?.data?.domain) return response.data.domain
    return
  },
  async confirmDomain(context, payload){
    await axios.post(context.getters.url1+`user/domains/${payload.domain_id}/confirm`, payload)
  },
  async removeDomain(context, payload){
    await axios.delete(context.getters.url1+'user/domains/'+payload)
  },
  async checkDkim(context, payload){
    await axios.post(context.getters.url1+`user/domains/${payload.domain_id}/check`, payload)
  },
  async getDomainEmails(context, payload){
    const response = await axios.get(context.getters.url1+`user/domains/${payload}/emails`)
    if(response?.data) return response.data
    return
  },
  async postDomainEmail(context, payload){
    const response = await axios.post(context.getters.url1+`user/domains/${payload.email.domain_id}/emails`, payload)
    if(response?.data) return response.data
    return
  },
  async putDomainEmail(context, payload){
    await axios.put(context.getters.url1+'user/domains/' + payload.id + '/emails/' + payload.email_id, payload.payload)
  },
  async removeDomainEmail(context, payload){
    if(payload.isDelegated){
      await axios.patch(context.getters.url1 + 'user/domains/' + payload.id + '/emails/' + payload.email_id)
    } else {
      await axios.delete(context.getters.url1 + 'user/domains/' + payload.id + '/emails/' + payload.email_id)
    }
    context.commit('removeAliasAccessed', payload.alias)
  },
  async loadMessageById(context, payload){
    const response = await axios.get(context.getters.url1+'messages?message_id='+payload)
    if(response?.data) return response.data
    return
  },
  async getSignOn(){
    const response = await axios.create({withCredentials: true}).get(SIGN_ON_URL)
    if(response?.data) return response.data
    return
  },
  async confirmDomainEmail(context, payload){
    await axios.post(context.getters.url1+`user/domains/emails/${payload.email_id}/confirm`, payload.payload)
  },
  async ba_impersonation(context, payload){
    await axios.post(context.getters.url1+'user/settings/security/ba-impersonation?wmid='+payload)
  },
  async postPaidDomain(context, payload){
    const response = await axios.post(context.getters.url1+'user/domains', payload)
    if(response?.data?.domain) return response.data.domain
    return
  },
  async checkPayment(context, payload){
    const response = await axios.post(context.getters.url1+`user/domains/${payload.domain_id}/paid`, payload)
    if(response?.data?.domain) return response.data.domain
    return
  },
  async checkEmailPayment(context, payload){
    const response = await axios.post(context.getters.url1+`user/domains/${payload.domain_id}/emails/${payload.email_id}/paid`, {})
    if(response?.data?.email) return response.data.email
    return
  },
  async savePaidAlias(context, payload){
    await axios.post(context.getters.url1+"user/aliases/paid", { ...payload})
  },
  async checkAliasPayment(context, payload){
    const response = await axios.post(context.getters.url1+`user/aliases/paid/${payload.id}/check_payment`, {})
    if(response?.data?.paid_alias) return response.data.paid_alias
    return
  },
  async getUnpaidAliases(context){
    const response = await axios.get(context.getters.url1+`user/aliases/paid`)
    if(response?.data?.paid_aliases) return response.data.paid_aliases
    return
  },
  async getAllowedDomains(context){
    const response = await axios.get(context.getters.url1+`user/domains/public`)
    if(response?.data?.domains) return response.data.domains
    return
  },
  async removeUnpaidAlias(context, payload){
    await axios.delete(context.getters.url1+'user/aliases/paid/'+payload.id)
  },
  setIsSearch(context, payload){
    context.commit("setIsSearchMode", payload)
  },
  searchPagination(context, payload){
    context.commit("setSearchPagination", payload)
  },
  async removeSearch(context, payload){
    let signal = {}
    if(payload?.controller?.signal){
      signal = {
        signal: payload.controller.signal
      }
    }
    const response = await axios.post(context.getters.url1+"messages/search/delete", payload.payload, signal)
    if(response?.data){
      if(response.data.messages){
        context.commit("setMails", response.data.messages)
      }
      context.commit("setTotal", response.data.total)
      context.commit("setAll", response.data.all)
    }
  },
  async moveAllFolder(context, payload){
    let signal = {}
    if(payload?.controller?.signal){
      signal = {
        signal: payload.controller.signal
      }
    }
    await axios.post(context.getters.url1+"messages/search/move", payload.payload, signal)
  },
  async markAllFolder(context, payload){
    let signal = {}
    if(payload?.controller?.signal){
      signal = {
        signal: payload.controller.signal
      }
    }
    await axios.post(context.getters.url1+"messages/search/mark", payload.payload, signal)
  },
  async removeAlias(context, payload) {
    await axios.delete(context.getters.url1+'user/aliases/'+payload.id)
    context.commit('removeAliasAccessed', payload.alias)
  },
  setUpdatedMailsList(context, payload){
    context.commit("updateMailsList", [...payload])
  },
  async checkCollectorEmail(context, payload){
    const response = await axios.post(context.getters.url1+'user/collectors/find_imap', payload)
    if(response?.data) return response.data
    return
  },
  async confirmCollectorEmail(context, payload){
    const response = await axios.post(context.getters.url1+'user/collectors', payload)
    if(response?.data) return response.data
    return
  },
  async removeCollector(context, payload){
    await axios.delete(context.getters.url1+'user/collectors/'+payload)
    return
  },
  async getCollectors(context){
    const response = await axios.get(context.getters.url1+`user/collectors `)
    if(response?.data) {
      context.commit('setCollectors', response.data)
      return response.data
    }
    return
  },
  async setEnable(context, payload) {
    const response = await axios.put(context.getters.url1+`user/collectors/${payload.id}`, payload)
    if(response?.data) {
      context.commit('setCollector', response.data)
      return response.data
    }
    return
  },
  async removeCollectorFromStore(context, payload){
    context.commit('removeCollector', payload)
  },
  async updateCollectorEmail(context, payload){
    const response = await axios.put(context.getters.url1+'user/collectors/'+payload.id, payload)
    if(response?.data) {
      context.commit('setCollector', response.data)
      return response.data
    }
    return
  },
  updateMailsListSettings(context){
    context.commit('updateMailsListSettings')
  },
  async searchWmidOrAlias(context, payload) {
    const response = await axios.post(context.getters.url1+'user/settings/security/userinfo', payload)
    if(response?.data) {
      return response.data
    }
  },
  setSocket(context, payload) {
    context.commit('setSocket', payload)
  }
  // async searchEmail(context, payload) {
  //   const response = await axios.post(context.getters.url1+'user/settings/security/user_for_aliases', payload)
  //   console.log('responseEMAIL', response)
  // }
}
